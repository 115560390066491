
import {Options, Vue} from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import {requests} from "@/helpers/requests";
import CartAlert from "./components/CartAlert.vue";
import Footer from "./components/Footer.vue";
import {CanOrderResponse} from "@/responses/webshop";
import {PageMenusResponse, TranslationsResponse} from "@/responses/pages";
import {CartResponse} from "@/responses/cart-resp";

@Options({
    components: {
        NavBar,
        CartAlert,
        Footer,
    },
    created() {

        if (process.env.NODE_ENV === "development") {
            this.$i18n.locale = "nl";
        } else {
            if (location.origin.indexOf(".fr") !== -1 || location.pathname === "/fr")
                this.$i18n.locale = "fr";
            else if (
                location.origin.indexOf(".be") !== -1 ||
                location.pathname === "/be"
            )
                this.$i18n.locale = "nl";
            else if (
                location.origin.indexOf(".nl") !== -1 ||
                location.pathname === "/nl"
            )
                this.$i18n.locale = "nl";
            else if (
                location.origin.indexOf(".de") !== -1 ||
                location.pathname === "/de"
            )
                this.$i18n.locale = "de";
            else if (
                location.origin.indexOf(".com") !== -1 ||
                location.pathname === "/en"
            ) {
                this.$i18n.locale = "en";
            }
            else{
                this.$i18n.locale = "nl";
            }
        }

        requests.get("webshop/can_order").then((response: CanOrderResponse) => {
            this.$store.commit("setBlockedDates", response.blockedDates);
            this.blockedDates = response.blockedDates;
        });
        // this.getMenus();
        this.getTranslations();

    },
    watch: {
        "$i18n.locale": function () {
            // this.getMenus();
            this.getTranslations();
        },
    },
    methods: {
        getMenus() {
            requests
                .get("pages/menus/" + this.$i18n.locale)
                .then((response: PageMenusResponse) => {
                    this.$store.commit("setMenus", response.menus);
                });
        },
        getTranslations() {
            requests
                .get("translations/get?locale=" + this.$i18n.locale)
                .then((response: TranslationsResponse) => {
                    this.$store.commit("setTranslations", response.translations);
                    requests.get("checkout/cart").then((response: CartResponse) => {
                        this.$store.commit("setCart", response.data);
                    });
                });
        },
    },
})
export default class App extends Vue {
}
