
import {Options, Vue} from "vue-class-component";
import {requests} from "@/helpers/requests";
import {CartResponse} from "@/responses/cart-resp";
import {Product} from "@/models/product";
import {useToast} from "vue-toast-notification";

@Options({
    name: "ProductItem",
    props: {
        product: Object,
        height: {
            type: String,
            default: "277px",
        },
    },
    created() {
        this.getLength();
        this.product.productName = Product.translateName(
            this.product.name,
            this.$store.state.translations
        );
    },
    data() {
        return {
            showPanel: false,
            amount: 1,
            length: null,
        };
    },
    methods: {
        showPops() {
            this.showPanel = !this.showPanel;
        },
        addToCart(event: MouseEvent) {
            console.log(this.product)
            event.stopImmediatePropagation();
            event.preventDefault();
            requests
                .post("checkout/cart", {
                    product_id: this.product.sku,
                    quantity: this.amount,
                })
                .then((response: CartResponse) => {
                    this.productAdded = true;
                    if (response.data){
                        this.$store.commit("setCart", response.data);
                        useToast().success(response.message)
                    }
                    else{
                        useToast().error(response.message)
                    }
                });
        },
        getOther() {
            const main = this.product.images[0].path;
            for (const index in this.product.images) {
                if (this.product.images[index].path !== main) {
                    return this.product.images[index].path;
                }
            }
            return main;
        },
        getLength() {

            if (this.product.productKenmerken == null) {
                return 0;
            }
            for (const index in this.product.productKenmerken) {
                if (this.product.productKenmerken[index][0] === "Minimum steellengte") {
                    this.length = this.product.productKenmerken[index][1];
                }
            }
        },
    },
})
export default class ProductItem extends Vue {
}
